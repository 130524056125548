import React from 'react';
import { Typography } from 'antd';
import { NavLink } from 'react-router-dom';

function Footer(): React.ReactNode {
  return (
    <Typography.Text strong style={{ textAlign: 'center', display: 'block', width: '100%' }}>
      Do you have an account? &thinsp;
      <NavLink to="/sign-in">
        Sign In
      </NavLink>
    </Typography.Text>
  );
}

export default Footer;
