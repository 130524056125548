import React from 'react';
import { config } from '@triare/auth-redux';
import Simple from '../../components/Layout/Simple';

const { displayName } = config;

export default function NotFound(): React.ReactNode {
  document.title = `${displayName}: Not found`;

  return (
    <Simple>
      <div>Not found</div>
    </Simple>
  );
}
