import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import Wrapper from '../../Wrapper';

import styles from './index.module.scss';

function Information(): React.ReactNode {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div className={styles.content}>
        <h1>
          Thank you for registering!
        </h1>

        <div>
          Please check the email message we&apos;ve sent you and follow the link to confirm your email address.
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          If you do not receive this email within 10 minutes - please check your "Spam" folder.
        </div>

        <Button type="primary" onClick={() => navigate('/')}>
          Go to home page
        </Button>
      </div>
    </Wrapper>
  );
}

export default Information;
