import { combineReducers, configureStore, Tuple } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { spawn } from 'redux-saga/effects';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { useDispatch } from 'react-redux';
import { Store } from 'redux';
import {
  moduleName as authModuleName, auth, saga as authSaga,
} from './auth';
import { moduleName as userModuleName, user, saga as userSaga } from './user';
import { moduleName as commonModuleName, common, saga as commonSaga } from './common';

export const sagaMiddleware = createSagaMiddleware();

const store: Store = configureStore({
  reducer: combineReducers({
    [authModuleName]: auth.reducer,
    [userModuleName]: user.reducer,
    [commonModuleName]: common.reducer,
  }),
  middleware: () => new Tuple(sagaMiddleware),
  devTools: process.env.REACT_APP_ENV !== 'production',
});

const storeType = (store as EnhancedStore);

export type RootState = ReturnType<typeof storeType.getState>;
export type AppDispatch = typeof store.dispatch;

const useAppDispatch = () => useDispatch<AppDispatch>();

sagaMiddleware.run(function* runSaga() {
  yield spawn(authSaga, store);
  yield spawn(commonSaga, store);
  yield spawn(userSaga);
});

export default store;
