import React from 'react';
import { Col, Row } from 'antd';

import styles from './index.module.scss';

export interface WrapperProps {
  children: React.ReactNode
}

export default function Wrapper({ children }: WrapperProps): React.ReactNode {
  return (
    <Row justify="center">
      <Col className={styles.wrapper}>
        <img src="/logo.png" className={styles.logo} alt="Triare" />

        <div className={styles.content}>
          {children}
        </div>
      </Col>
    </Row>
  );
}
